import * as React from "react";
import { Link } from "gatsby";
import ContactForm from "@components/ContactForm";
import * as styles from "./Engagement.module.scss";

import iconsAlexa from "@images/engagement/icons_alexa.svg";
import iconsAWS from "@images/engagement/icons_aws.svg";
import iconsChatbot from "@images/engagement/icons_chatbot.svg";

import awsCalls from "@images/graphics/aws_calls.svg";
import database from "@images/graphics/database.svg";
import chatbot from "@images/graphics/chatbot.svg";



const EngagementComponent = ({metadata}) => {

  /*
  const engagementTypes = [
    {
      id: "aws-call-centers",
      title: "AWS powered call centers",
      content: "AWS Connect allows you to add instant, on-demand phone support to your product. When a customer has a question or concern, they your business's phone (or ask Alexa), and you receive the call. You can reroute the call at any time via the AWS console, get detailed information about who called and when, and then instantly connect with them by calling them back, sending a text message through SMS, or by using AWS Lex to send an automated response.",
      image: awsCalls,
      icons: iconsAWS,
      tagline: "Are you ready to start automating?",
      cta: "Yes, let's talk!",
      alt: "phone and AWS logo"
    },
    {
      id: "ai-chatbots",
      title: "AI powered chatbots",
      content: "The Agent on-line customer service chatbot makes it easy to answer questions, take orders, and assist your customers. The Agent is powered by AWS and can be trained to react to almost any customer input. Get started today!",
      image: chatbot,
      icons: iconsChatbot,
      tagline: "You are one click away from getting started",
      cta: "Get in touch!",
      alt: "person talking to chatbot"

    },
    {
      id: "alexa-integrations",
      title: "Alexa Integrations",
      content: "Wouldn’t it be nice to connect customers in new ways? By integrating your product with Amazon Alexa, you can easily provide information to customers and enhance their experience.", //[hyperlink https://developer.amazon.com/en-US/alexa/devices/connected-devices}
      image: database,
      icons: iconsAlexa,
      tagline: "Let's make your business more accessible",
      cta: "Contact us!",
      alt: "phone and dialog bubble"
    }
  ];
  */

  const ICONS = {
    "iconsAWS": iconsAWS,
    "iconsChatbot": iconsChatbot,
    "iconsAlexa": iconsAlexa
  }

  const IMAGE = {
    "awsCalls": awsCalls,
    "chatbot": chatbot,
    "database": database
  }

  const EnTypeCard = ({id, image, title, content, tagline, cta}) => {


    return (
      <article id={id} className={styles.en_type_card}>
        <h2>{title}</h2>
        <div style={{backgroundImage: `url(${image})`}} className={styles.en_type_img}></div>
        <p>{content}</p>
        <div>
        <p className={styles.tagline}>{tagline}</p>
        <a href="#form" className={styles.contact_button}>{cta}</a>
        </div>
      </article>
    )
  }

  return (
    <div className="container">
      <section className={styles.header}>
        <h1>{metadata.title}</h1>

        <div className={styles.type_buttons_container}>
          {metadata.engagement.map(type => (
            <a href={`#${type.id}`} className={styles.type_button}>
              <span>{type.title}</span>
                <img src={ICONS[type.icons]} className={styles.type_icons} alt={type.alt} />
            </a>
          ))}
        </div>

        <p className={styles.need_p}>
          {metadata.subtitle_1}<br />
          <Link to="#contact-form">Contact us</Link> {metadata.subtitle_2}
        </p>
      </section>

      <section className={styles.en_type_cards}>
        {metadata.engagement.map(type => 
          <EnTypeCard  {...type} image={IMAGE[type.image]} icons={ICONS[type.icons]} />
          )}
      </section>
      
      <section className={`${styles.contact_form} container`} id="form">
        <ContactForm />
      </section>
    </div>
  );
};

export default EngagementComponent;
