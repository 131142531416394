// extracted by mini-css-extract-plugin
export const header = "Engagement-module--header--2Iu6i";
export const type_button = "Engagement-module--type_button--1JWao";
export const type_icons = "Engagement-module--type_icons--1ApPJ";
export const bouncy = "Engagement-module--bouncy--NEfSM";
export const need_p = "Engagement-module--need_p--2Xgfe";
export const en_type_card = "Engagement-module--en_type_card--1KBnN";
export const tagline = "Engagement-module--tagline--2HjQn";
export const en_type_img = "Engagement-module--en_type_img--1sRrr";
export const contact_button = "Engagement-module--contact_button--3OHEg";
export const type_buttons_container = "Engagement-module--type_buttons_container--200dh";
export const wiggleRight = "Engagement-module--wiggle-right--19gjS";
export const contact_form = "Engagement-module--contact_form--2QOlK";
export const pop = "Engagement-module--pop--1ZfYF";