import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import SEO from "@components/seo";
import EngagementComponent from "@components/Engagement";
import { MetadataService } from "../../utils/MetadataService";

const EngagementPage = ({ uri, data }) => {
  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;
  return (
    <Layout path={uri}>
      <SEO title="Engagement | Magyk Software" />
      <EngagementComponent metadata={metadata} />
    </Layout>
  );
} 


export const pageQuery = graphql`
  query ServiceEngagementComponentQuery {
    pageMetadata(page_id: {eq: "service_engagement"}) {
      content,
      page_id
    }
  }
`
export default EngagementPage;
